// Global
@import 'global/functions';
@import 'global/reset';
@import 'global/typography';
@import 'global/global-styles';
@import 'global/theme';
@import 'global/animations';

/** 
 * GLOBAL VARIABLES
 * Global variables that should be accessible in all authored CSS
 */
$global-gutter: get-size(500);
$global-gutter-narrow: get-size(300);
$global-radius-large: .5rem;

// Composition
@import 'composition/auto-grid';
@import 'composition/cluster';
@import 'composition/content-group';
@import 'composition/reel';
@import 'composition/sidebar';
@import 'composition/splitter';

// Utilities
@import 'utilities/aspect-ratio';
@import 'utilities/bg-dot';
@import 'utilities/bg-pattern';
@import 'utilities/bg-texture';
@import 'utilities/dash-border';
@import 'utilities/dot';
@import 'utilities/flow';
@import 'utilities/full-bleed';
@import 'utilities/icon';
@import 'utilities/inner-wrapper';
@import 'utilities/region';
@import 'utilities/scrollbar';
@import 'utilities/text-columns';
@import 'utilities/tint';
@import 'utilities/vertical-separator';
@import 'utilities/visually-hidden';
@import 'utilities/wrapper';

// Block
@import 'block/accordion';
@import 'block/alert';
@import 'block/backlink'; //
@import 'block/brand';
@import 'block/btn';
@import 'block/burger-menu';
@import 'block/callout';
@import 'block/card';
@import 'block/carousel';
@import 'block/closer';
@import 'block/events-list';
@import 'block/eyebrow';
@import 'block/form';
@import 'block/headline';
@import 'block/hero-image';
@import 'block/icon-button';
@import 'block/landing-page';
@import 'block/leaves';
@import 'block/nav';
@import 'block/nav-panel';
@import 'block/page';
@import 'block/pagination';
@import 'block/partners'; // cubify?
@import 'block/pill';
@import 'block/post';
@import 'block/post-list';
@import 'block/preview-pagination';
@import 'block/script';
@import 'block/shedule';
@import 'block/signup-form';
@import 'block/site-foot';
@import 'block/site-head';
@import 'block/skip-link';
@import 'block/social-square';
@import 'block/text-highlight';
@import 'block/tabs';
@import 'block/tooltip';

// dependancy - $site-head__height
@import 'block/cover'; // composition
@import 'block/hero'; // composition
@import 'block/map';